
import { computed, defineComponent, reactive } from '@vue/composition-api'
import { mdiLogin, mdiLogout, mdiAccount, mdiTune, mdiEarth, mdiCommentOutline, mdiInformationOutline } from '@mdi/js'

import { version as rekoVersion } from '../../../../package.json'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'

import { resolveCountry } from '@/plugins/i18n'

import { filteredNavItems } from '@/router/utils/navItemFilter'

import { useAuthActions, useAuthGetters, useLocaleActions } from '@/store'

export default defineComponent({
  name: 'AppBar',
  components: {
    CommonFlagIcon,
  },
  props: {
    isNavOpen: {
      type: Boolean,
      required: false,
    },
  },
  setup: (_, { root }) => {
    const STAGING_HOSTNAMES = [
      'reko.staging.dev.frachtwerk.de',
      'it-ressource-management.fw-web.space',
      'rtool.rt100.dbmc.comp.db.de',
    ]

    const ENV =
      process.env.NODE_ENV === 'development'
        ? `Development (${rekoVersion})`
        : STAGING_HOSTNAMES.includes(location.hostname)
        ? `Staging (${rekoVersion})`
        : rekoVersion

    const { isAuthenticated } = useAuthGetters()

    const { setCurrentLocale } = useLocaleActions()

    const currentLocale = computed(() => root.$store.state.locale.currentLocale)
    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    const { logout } = useAuthActions()

    const title = computed(() => (root.$route.meta?.title ? root.$t(root.$route.meta?.title) : ''))

    function isCurrentRouteActive(route: string): boolean {
      const regEx = new RegExp(`^${route}(/|$)`)

      return Boolean(root.$route.path.match(regEx))
    }

    return reactive({
      icons: {
        mdiLogin,
        mdiLogout,
        mdiAccount,
        mdiTune,
        mdiEarth,
        mdiCommentOutline,
        mdiInformationOutline,
      },
      constants: {
        ENV,
      },
      state: {
        filteredNavItems,

        isAuthenticated,

        currentLocale,
        implementedLocales,

        title,
      },
      functions: {
        resolveCountry,

        setCurrentLocale,

        logout,

        isCurrentRouteActive,
      },
    })
  },
})
