import { computed } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import {
  mdiAccount,
  mdiTranslate,
  mdiShieldAccount,
  mdiShieldHalfFull,
  mdiAccountGroup,
  mdiAccountDetails,
  mdiShieldCheck,
  mdiCashMultiple,
  mdiFileDocumentMultiple,
  mdiSendOutline,
  mdiFormatListText,
  mdiTruckDelivery,
  mdiDatabase,
  mdiHomeCity,
  mdiAccountConvert,
  mdiTextBoxCheckOutline,
  mdiFolderStarMultiple,
  mdiBellRing,
  mdiClipboardMultiple,
  mdiCheckboxMarkedOutline,
  mdiToolbox,
  mdiFormatListChecks,
  mdiTextBox,
  mdiFileTree,
  mdiFamilyTree,
  mdiAccountPlus,
  mdiAccountMultiplePlus,
  mdiFileDocumentMultipleOutline,
  mdiCalendarClock,
  mdiTimetable,
  mdiBriefcaseClock,
  mdiFormatListBulletedType,
  mdiCheckboxOutline,
  mdiAccountClock,
  mdiChartTimeline,
  mdiCompareHorizontal,
  mdiFileExport,
} from '@mdi/js'

import { useAuthGetters } from '@/store'

import { Rights } from '@/api/types/right'

const { isAuthenticated, userRights } = useAuthGetters()

interface NavItem {
  title: TranslateResult
  icon?: string
  to: string
  auth?: boolean
  rights?: string[]
  children?: NavItem[]
}

const navItems: NavItem[] = [
  {
    title: 'persons.title',
    to: '/persons',
    icon: mdiAccountGroup,
    auth: true,
    rights: [
      Rights.PERSON_READ_ALL,
      Rights.PERSON_READ_OWN,
      Rights.CHECKLIST_READ_OWN,
      Rights.CHECKLIST_READ_ALL,
      Rights.CHECKLIST_TEMPLATE_READ,
    ],
    children: [
      {
        title: 'persons.persons.title',
        to: '/persons/list',
        icon: mdiAccountDetails,
        auth: true,
      },
      {
        title: 'persons.checklists.title',
        to: '/persons/checklist',
        icon: mdiCheckboxOutline,
        auth: true,
        rights: [
          Rights.CHECKLIST_READ_OWN,
          Rights.CHECKLIST_READ_ALL,
          Rights.CHECKLIST_TEMPLATE_READ,
          Rights.BASIC_READ,
        ],
      },
    ],
  },
  {
    title: 'contractData.title',
    to: '/contract-data',
    icon: mdiFileDocumentMultiple,
    auth: true,
    rights: [
      Rights.ASSIGNMENT_READ_ALL,
      Rights.ASSIGNMENT_READ_OWN,
      Rights.CONTRACT_READ_ALL,
      Rights.CONTRACT_READ_OWN,
      Rights.SUPPLIER_READ,
    ],
    children: [
      {
        title: 'contractData.assignments.title',
        to: '/contract-data/assignments',
        icon: mdiSendOutline,
        auth: true,
        rights: [Rights.ASSIGNMENT_READ_ALL, Rights.ASSIGNMENT_READ_OWN],
      },
      {
        title: 'contractData.contracts.title',
        to: '/contract-data/contracts',
        icon: mdiFormatListText,
        auth: true,
        rights: [Rights.CONTRACT_READ_ALL, Rights.CONTRACT_READ_OWN],
      },
      {
        title: 'contractData.suppliers.title',
        to: '/contract-data/suppliers',
        icon: mdiTruckDelivery,
        auth: true,
        rights: [Rights.SUPPLIER_READ],
      },
    ],
  },
  {
    title: 'administration.title',
    to: '/administration',
    icon: mdiClipboardMultiple,
    auth: true,
    rights: [
      Rights.TASK_READ_ALL,
      Rights.TASK_READ_OWN,
      Rights.EQUIPMENT_READ_ALL,
      Rights.EQUIPMENT_READ_OWN,
      Rights.NOTE_READ_ALL,
      Rights.NOTE_READ_OWN,
    ],
    children: [
      {
        title: 'administration.tasks.title',
        to: '/administration/tasks',
        icon: mdiCheckboxMarkedOutline,
        auth: true,
        rights: [Rights.TASK_READ_ALL, Rights.TASK_READ_OWN],
      },
      {
        title: 'administration.equipments.title',
        to: '/administration/equipments',
        icon: mdiToolbox,
        auth: true,
        rights: [Rights.EQUIPMENT_READ_ALL, Rights.EQUIPMENT_READ_OWN],
      },
      {
        title: 'administration.notification.title',
        to: '/administration/notes',
        icon: mdiBellRing,
        auth: true,
        rights: [Rights.NOTE_READ_ALL, Rights.NOTE_READ_OWN],
      },
      {
        title: 'administration.exportFiles.title',
        to: '/administration/export-files',
        icon: mdiFileExport,
        auth: true,
        rights: [Rights.EXPORT_FILE_READ],
      },
    ],
  },
  {
    title: 'acquisition.title',
    to: '/acquisition',
    icon: mdiAccountMultiplePlus,
    auth: true,
    rights: [
      Rights.DEMAND_READ_ALL,
      Rights.DEMAND_READ_OWN,
      Rights.TENDERING_READ_ALL,
      Rights.TENDERING_READ_OWN,
      Rights.APPLICATION_READ_ALL,
      Rights.APPLICATION_READ_OWN,
      Rights.APPLICANT_READ_ALL,
      Rights.APPLICANT_READ_OWN,
    ],
    children: [
      {
        title: 'acquisition.demands.title',
        to: '/acquisition/demands',
        icon: mdiFormatListChecks,
        auth: true,
        rights: [Rights.DEMAND_READ_ALL, Rights.DEMAND_READ_OWN],
      },
      {
        title: 'contractData.tenderings.title',
        to: '/acquisition/tenderings',
        icon: mdiTextBox,
        auth: true,
        rights: [Rights.TENDERING_READ_ALL, Rights.TENDERING_READ_OWN],
      },
      {
        title: 'acquisition.applications.title',
        to: '/acquisition/applications',
        icon: mdiFileDocumentMultipleOutline,
        auth: true,
        rights: [Rights.APPLICATION_READ_ALL, Rights.APPLICATION_READ_OWN],
      },
      {
        title: 'acquisition.applicants.title',
        to: '/acquisition/applicants',
        icon: mdiAccountPlus,
        auth: true,
        rights: [Rights.APPLICANT_READ_ALL, Rights.APPLICANT_READ_OWN],
      },
    ],
  },
  {
    title: 'planning.title',
    to: '/planning',
    icon: mdiBriefcaseClock,
    auth: true,
    rights: [
      Rights.WORKING_DAYS_PLANNED_READ,
      Rights.PRODUCT_INCREMENT_INTERVAL_READ,
      Rights.CAPACITY_READ,
      Rights.POSITION_READ_ALL,
      Rights.POSITION_READ_OWN,
    ],
    children: [
      {
        title: 'planning.capacity.title',
        to: '/planning/capacity',
        icon: mdiTimetable,
        auth: true,
        rights: [Rights.CAPACITY_READ],
      },
      {
        title: 'planning.timePlanning.title',
        to: '/planning/time-planning',
        icon: mdiCalendarClock,
        auth: true,
        rights: [Rights.WORKING_DAYS_PLANNED_READ, Rights.PRODUCT_INCREMENT_INTERVAL_READ],
      },
      {
        title: 'planning.workforcePlanning.title',
        to: '/planning/workforce-planning',
        icon: mdiAccountClock,
        auth: true,
        rights: [Rights.POSITION_READ_ALL, Rights.POSITION_READ_OWN],
      },
      {
        title: 'planning.positionTimeline.title',
        to: '/planning/position-timeline',
        icon: mdiChartTimeline,
        auth: true,
        rights: [Rights.POSITION_READ_ALL, Rights.POSITION_READ_OWN],
      },
      {
        title: 'planning.workforcePlanning.comparison.title',
        to: '/planning/comparison',
        icon: mdiCompareHorizontal,
        auth: true,
        rights: [
          Rights.POSITION_READ_ALL,
          Rights.POSITION_READ_OWN,
          Rights.POSITION_SCENARIO_READ,
          Rights.ASSIGNMENT_READ_ALL,
          Rights.ASSIGNMENT_READ_OWN,
        ],
      },
    ],
  },
  {
    title: 'baseData.title',
    to: '/base-data',
    icon: mdiDatabase,
    auth: true,
    rights: [
      Rights.COST_CENTER_READ,
      Rights.PSP_ACCOUNT_READ,
      Rights.CORPORATE_IDENTIFIER_READ,
      Rights.FACILITY_CONSTRUCT_READ,
      Rights.ACCOUNT_READ,
      Rights.PROFESSIONAL_UNIT_READ,
      Rights.ORGANIZATIONAL_UNIT_READ,
      Rights.CORPORATE_UNIT_READ,
      Rights.PROFILE_READ,
      Rights.INVENTORY_ITEM_READ_ALL,
      Rights.INVENTORY_ITEM_READ_OWN,
      Rights.WORK_PLACE_READ,
      Rights.WORKING_DAYS_PLANNED_READ,
      Rights.PRODUCT_INCREMENT_INTERVAL_READ,
      Rights.ENUM_ITEM_READ,
      Rights.CHECKLIST_TEMPLATE_READ,
    ],
    children: [
      {
        title: 'baseData.budget.title',
        to: '/base-data/budget',
        icon: mdiCashMultiple,
        auth: true,
        rights: [
          Rights.COST_CENTER_READ,
          Rights.PSP_ACCOUNT_READ,
          Rights.CORPORATE_IDENTIFIER_READ,
          Rights.FACILITY_CONSTRUCT_READ,
          Rights.ACCOUNT_READ,
        ],
      },
      {
        title: 'baseData.profUnits.title',
        to: '/base-data/prof-units',
        icon: mdiFileTree,
        auth: true,
        rights: [Rights.PROFESSIONAL_UNIT_READ],
      },
      {
        title: 'baseData.corporateStructure.title',
        to: '/base-data/corporate-structure',
        icon: mdiFamilyTree,
        auth: true,
        rights: [Rights.ORGANIZATIONAL_UNIT_READ, Rights.CORPORATE_UNIT_READ],
      },
      {
        title: 'baseData.profiles.title',
        to: '/base-data/profiles',
        icon: mdiAccountConvert,
        auth: true,
        rights: [Rights.PROFILE_READ],
      },
      {
        title: 'baseData.checklistTemplates.title',
        to: '/base-data/checklist-templates',
        icon: mdiTextBoxCheckOutline,
        auth: true,
        rights: [Rights.CHECKLIST_TEMPLATE_READ],
      },
      {
        title: 'baseData.inventoryItems.title',
        to: '/base-data/inventory-items',
        icon: mdiFolderStarMultiple,
        auth: true,
        rights: [Rights.INVENTORY_ITEM_READ_ALL, Rights.INVENTORY_ITEM_READ_OWN],
      },
      {
        title: 'baseData.workPlaces.title',
        to: '/base-data/work-places',
        icon: mdiHomeCity,
        auth: true,
        rights: [Rights.WORK_PLACE_READ],
      },
      {
        title: 'baseData.enumItems.title',
        to: '/base-data/enum-items',
        icon: mdiFormatListBulletedType,
        auth: true,
        rights: [Rights.ENUM_ITEM_READ],
      },
    ],
  },
  {
    title: 'admin.title',
    to: '/admin',
    icon: mdiShieldHalfFull,
    auth: true,
    rights: [Rights.USER_READ, Rights.TRANSLATION_READ, Rights.ROLE_READ, Rights.RIGHT_READ],
    children: [
      {
        title: 'admin.users.title',
        to: '/admin/users',
        icon: mdiAccount,
        auth: true,
        rights: [Rights.USER_READ],
      },
      {
        title: 'admin.translations.title',
        to: '/admin/translations',
        icon: mdiTranslate,
        auth: true,
        rights: [Rights.TRANSLATION_READ],
      },
      {
        title: 'admin.roles.title',
        to: '/admin/roles',
        icon: mdiShieldCheck,
        auth: true,
        rights: [Rights.ROLE_READ],
      },
      {
        title: 'admin.rights.title',
        to: '/admin/rights',
        icon: mdiShieldAccount,
        auth: true,
        rights: [Rights.RIGHT_READ],
      },
    ],
  },
]

const navItemFilter = (item: NavItem): boolean => {
  if (!isAuthenticated.value) return false

  if (item.rights) {
    return item.rights.some((v) => userRights.value.includes(v))
  } else {
    return true
  }
}

const filteredNavItems = computed(() =>
  navItems
    .filter((item) => navItemFilter(item))
    .map((item) => {
      return {
        ...item,
        children: item.children?.filter((child) => navItemFilter(child)),
      }
    })
)

export { filteredNavItems }
