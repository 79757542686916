var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppBar'), _c('v-main', [_c('router-view', {
    key: _vm.$route.path
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }