
import { defineComponent } from '@vue/composition-api'

import AppBar from '@/layouts/default/AppBar.vue'

export default defineComponent({
  name: 'PublicLayout',
  components: {
    AppBar,
  },
})
