var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "app": "",
      "color": "secondary",
      "elevation": "1"
    }
  }, [!_vm.isNavOpen ? _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/images/DB_logo_red_100px_rgb.svg"),
      "width": "50px",
      "alt": "DB Logo",
      "contain": ""
    }
  })], 1) : _vm._e(), _c('v-toolbar-title', {
    staticClass: "px-5 db-text-color"
  }, [_vm._v(" " + _vm._s(_vm.state.title) + " ")]), _c('v-spacer'), _c('ul', {
    staticClass: "menu"
  }, [_vm._l(_vm.state.filteredNavItems, function (navItem) {
    return [navItem.children && navItem.children.length ? _c('v-menu', {
      key: navItem.name,
      attrs: {
        "open-on-hover": "",
        "close-delay": 250,
        "close-on-content": false,
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('li', {
            staticClass: "menu--parent"
          }, [navItem.icon ? [_c('div', _vm._g({}, on), [_c('v-icon', {
            class: ["mx-2  ".concat(_vm.functions.isCurrentRouteActive(navItem.to) && 'primary--text')],
            domProps: {
              "textContent": _vm._s(navItem.icon)
            }
          })], 1)] : _vm._e(), _c('a', _vm._g({
            class: ["menu--parent--text menu--parent__cursor ".concat(_vm.functions.isCurrentRouteActive(navItem.to) && 'primary--text')]
          }, on), [_vm._v(" " + _vm._s(_vm.$t(navItem.title)) + " ")])], 2)];
        }
      }], null, true)
    }, [navItem.children ? _c('v-list', {
      staticClass: "py-0"
    }, _vm._l(navItem.children, function (child) {
      return _c('div', {
        key: child.title
      }, [_c('router-link', {
        staticClass: "menu--child--text",
        attrs: {
          "to": child.to,
          "link": "",
          "exact-path": ""
        }
      }, [_c('v-list-item', {
        staticClass: "menu--child subheader"
      }, [child.icon ? [_c('v-icon', {
        class: ["mx-2 ".concat(_vm.functions.isCurrentRouteActive(child.to) && 'primary--text')],
        domProps: {
          "textContent": _vm._s(child.icon)
        }
      })] : _vm._e(), _c('v-list-item-title', {
        class: [" ".concat(_vm.functions.isCurrentRouteActive(child.to) && 'primary--text')]
      }, [_vm._v(" " + _vm._s(_vm.$t(child.title)) + " ")])], 2)], 1)], 1);
    }), 0) : _vm._e()], 1) : _c('li', {
      key: navItem.title,
      staticClass: "menu--child"
    }, [navItem.icon ? [_c('v-icon', {
      class: ["mx-2 ".concat(_vm.functions.isCurrentRouteActive(navItem.to) && 'primary--text')],
      domProps: {
        "textContent": _vm._s(navItem.icon)
      }
    })] : _vm._e(), _c('router-link', {
      staticClass: "menu--child--text",
      attrs: {
        "to": navItem.to,
        "link": "",
        "exact-path": "",
        "active-class": "primary--text"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(navItem.title)) + " ")])], 2)];
  })], 2), _c('v-menu', {
    attrs: {
      "offset-y": "",
      "nudge-right": "10",
      "close-on-content-click": false,
      "close-delay": "300"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', _vm._g({
          staticClass: "primary--text",
          attrs: {
            "elevation": "0",
            "icon": ""
          }
        }, on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiTune) + " ")])], 1)];
      }
    }])
  }, [_c('v-list', {
    staticClass: "py-0",
    attrs: {
      "dense": ""
    }
  }, [_vm.state.isAuthenticated ? _c('v-list-item', {
    attrs: {
      "to": {
        name: 'profile'
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiAccount) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t('profile.title')) + " ")])], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "to": {
        name: 'contact'
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiCommentOutline) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t('aboutUs.contact.title')) + " ")])], 1)], 1), _vm.state.isAuthenticated ? _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.functions.logout();
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiLogout) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t('login.logout')) + " ")])], 1)], 1) : _c('v-list-item', {
    attrs: {
      "to": {
        name: 'login'
      },
      "data-cy": "login-link"
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiLogin) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t('login.login')) + " ")])], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiInformationOutline) + " ")])], 1), _c('v-list-item-subtitle', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.constants.ENV) + " ")])], 1)], 1)], 1), !_vm.state.isAuthenticated ? _c('v-menu', {
    attrs: {
      "left": "",
      "bottom": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "elevation": "0",
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_vm.functions.resolveCountry(_vm.state.currentLocale) !== '' ? _c('CommonFlagIcon', {
          attrs: {
            "locale": _vm.functions.resolveCountry(_vm.state.currentLocale)
          }
        }) : _vm._e(), _vm.functions.resolveCountry(_vm.state.currentLocale) === '' ? _c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e()], 1)];
      }
    }], null, false, 1552219169)
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.state.implementedLocales, function (locale) {
    return _c('v-list-item', {
      key: locale,
      on: {
        "click": function click($event) {
          return _vm.functions.setCurrentLocale(locale);
        }
      }
    }, [_c('v-list-item-title', [_c('div', {
      staticClass: "d-flex align-center"
    }, [_vm.functions.resolveCountry(locale) !== '' ? _c('CommonFlagIcon', {
      staticClass: "mr-2",
      attrs: {
        "locale": _vm.functions.resolveCountry(locale)
      }
    }) : _vm._e(), _vm.functions.resolveCountry(locale) === '' ? _c('v-icon', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(locale))) + " ")], 1)])], 1);
  }), 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }